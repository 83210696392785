
import { Component, Vue } from "vue-property-decorator";
import { MedicalOrderService, WechatService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import globalConfig from "src/config/index";
import { formatDate } from "src/utils/extends";
import { log } from "console";

@Component
export default class GoodsDetail extends Vue
{

    /**
     * 温馨提示弹框
     * @private
     * @returns 
     */
    private showOverlay: boolean = false;

    /**
     * 加载中
     * @private
     * @returns 
     */
    private loading: boolean = false;

    /**
     * 是否显示日期控件
     * @private
     * @returns 
     */
    private showDate: boolean = false;

    /**
     * 是否显示确认日期控件
     * @private
     * @returns 
     */
    private showChangeDateDialog: boolean = false;

    /**
     * 是否显示确认退款弹框
     * @private
     * @returns 
     */
    private showRefundDialog: boolean = false;

    /**
     * 是否显示取消预约弹框弹框
     * @private
     * @returns 
     */
    private showCancelDialog: boolean = false;

    /**
     * 是否显示更多弹框
     * @private
     * @returns 
     */
    private showPopover: boolean = false;

    /**
     * 修改时间数据
     * @private
     * @returns 
     */
    private changeData: any =
    {
        id: null,
        changeTime: "",
        timePeriod: ""
    };

    /**
     * 数据模型
     * @private
     * @returns any
     */
    private dataModel: any =
    {
        appletAppId:null,
        actualAmount: null,
        appointEmployerMemberId: null,
        appointMemberId: null,
        appointMemberName: null,
        appointOrgId:null,
        appointOrgName: null,
        appointTime: null,
        appointTimePeriod: null,
        cancelTime: null,
        completedTime: null,
        createdTime: null,
        id: null,
        isNeedAppointment:true,
        isOvertimeUnPay: null, 
        marketPrice:null,
        orderNo:"",
        payDeadlineTime: null,
        payedTime: null,
        price: null,
        productId:null,
        productName:"",
        productPict:"",
        productType: 2,
        productTypeStr: "医疗服务",
        refundedTime: null, 
        refundingTime: null,
        sourceKind: 2,
        sourceKindStr: "零售购买",
        status: 2,
        statusStr:"",
        supplierId: null,
        supplierLogo: "",
        supplierName:"",
        isFromRights: false,
        rightsId: null,
        rightsName: "",
        healthServiceType:null,   // 线上医疗服务
        productsLink:"",
        m78UserToken:"",
        rightsOrderNo:null,
        memberId:null
    };

    /**
     * 商品订单id
     * @private
     * @returns string
     */
    private get id(): string
    {
        return this.$route.query && (this.$route.query.id as string) || "";
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    private reportContent: string = "";

    /**
     * 服务说明
     * @private
     * @returns boolean
     */
     private interpretOverlay: boolean = false;

     /**
     * dom加载完
     * @protected
     * @returns void
     */
     protected mounted() {
        this.init();
        this.reportContent =`
            <button class="test-btn"></button>
            <style>
                .test-btn{
                    width: 100%;
                    height: 520px;
                    background: transparent;
                    border: none;
                    color:rgba(255,255,255,0)
                }
                .test-btn:focus{outline: 0}
            </style>
            `
    }

    /**
     * 我知道了
     * @private
     * @returns void
     */
     private async onKnow() {
        this.interpretOverlay = false
    }

    /**
     * 线上去使用
     * @protected
     * @returns void
     */
     private toUse (item) {
        // 无跳转 NOJUMP = 0
        //  小程序 APPLET = 1
        //  URL URL = 2
        if(item.productsLinkType == 0) {  // 无跳转
            this.interpretOverlay = true

        } else {  // H5
            this.$router.push({name: "medical-buy"});  // 链接保存
            localStorage.setItem("productsLink", item.productsLink);
        }
    }

    /**
     * 初始化jssdk
     * @private
     * @returns Promise<any>
     */
     private async init(): Promise<any>
    {
        if(await tools.isWechat())
        {
            let url = location.href;
            let {content: result} = await WechatService.instance.getWechatJSSDK(url);
            if(result.data)
            {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: result.data.appId, // 必填，公众号的唯一标识
                    timestamp: result.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
                    signature: result.data.signature, // 必填，签名
                    jsApiList: ["openLocation"],
                    openTagList: ['wx-open-launch-weapp'] // 必填，需要使用的JS接口列表
                });

                wx.ready(function()
                {
                    
                });

            }
        }
        
    }

    /**
     * 跳转商品详情
     * @private
     * @returns string
     */
    private goMedicalDetail():void
    {
        this.$router.push({name:"medical", query:{id:this.dataModel.productId}});
    }
    /**
     * 继续支付
     * @private
     * @param {params}
     * @returns {Promise<void>}
     */
    private async proceedPay(): Promise<void>
    {
        this.$router.push({name: "pay", query:{orderNo: this.dataModel.orderNo}});
        // try
        // {
        //     let {content: result} =  await MedicalOrderService.instance.proceedPayMedicalOrder(this.id);

        //     if(result)
        //     {
        //         if(result.data.isNeedPay)
        //         {
        //             // Pay.onBridgeReady(result.data, this.getMedicalOrderDetail);
        //             this.$router.push({name: "pay", query:{orderNo: result.data.orderNo}});
        //         }
        //         else
        //         {
        //             this.getMedicalOrderDetail();
        //         }
        //     }
        // }
        // catch(err)
        // {
        //     Toast(err);
        // }
        
    }

    /**
     * 组件创建钩子
     * @protected
     * @returns void
     */
    protected created()
    {
        this.getMedicalOrderDetail();
        this.changeData.id = this.id;
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
     protected get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }


    /**
     * 获取订单详情
     * @protected
     * @returns void
     */
    protected async getMedicalOrderDetail(blo: boolean = false)
    {
        try
        {
            let {content: result} = await MedicalOrderService.instance.getMedicalOrderDetail(this.id);

            if(result.data)
            {
                this.dataModel = result.data;
                let type = null
                if(this.dataModel.productCategory == 2) {
                    type = 0
                } else {
                    type = 1
                }

                let data = {
                    token:this.dataModel.m78UserToken,
                    businessId:this.dataModel.patientPackageId,
                    memberId:this.dataModel.memberId,
                    rightsOrderNo:this.dataModel.rightsOrderNo,
                    rightsId:this.dataModel.rightsId,
                    businessNo:this.dataModel.orderNo,
                    type:type
                }

                let actionUrl = this.dataModel.productsLink && this.dataModel.productsLink.replace(/\(\(userId\)\)/g,this.userInfo && this.userInfo.userId);

                let cancelParams = {
                    appid: this.dataModel.appletAppId,
                    eleId:"apply", // 元素id
                    url: actionUrl,
                    content: this.reportContent, // 自定义的html内容
                    extradata:JSON.stringify(data)
                };
                
                if(result.data.status == 2 && !blo) {
                    this.wx_launch(cancelParams);
                }
                else if (result.data.status != 2 && blo)
                {
                    Toast("状态已变更！");
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    
    /**
     * 跳转小程序封装
     * @protected
     * @returns void
     */
    private async wx_launch(info): Promise<void> {
        if (!(await tools.isWechat())) {
            return;
        }
        var btn = document.getElementById(info.eleId); //获取元素
        if (!btn)
        {
            return;
        }
        let script = document.createElement("script");// 创建script内容插槽 避免template标签冲突
        script.type = "text/wxtag-template"; // 使用script插槽 必须定义这个type
        script.text = info.content // 自定义的html字符串内容
        let html = `<wx-open-launch-weapp style="width:100%; height:100%;display:block;overflow: hidden;" appid="${info.appid}" path="${info.url}" env-version="trial" extra-data='${info.extradata}' >${script.outerHTML}</wx-open-launch-weapp>`;
        btn.innerHTML = html; // html字符串赋值
        // 点击按钮 正常跳转触发
        btn.addEventListener("launch", function (e) {
            console.log("success");
        });
        // 点击跳转 抛出异常
        btn.addEventListener("error", function (e) {
            console.log("fail", e);
            alert(`跳转异常 - ${JSON.stringify(e)}`)
        });
    }

    /**
     * 取消服务订单
     * @protected
     * @returns void
     */
    protected async cancelMedicalOrder()
    {
        try
        {
            let res:any = await MedicalOrderService.instance.cancelMedicalOrder(this.id);
            Toast("操作成功!");
            this.getMedicalOrderDetail();
           
        }
        catch(err)
        {
            this.showOverlay = true;
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 服务订单退款
     * @protected
     * @returns void
     */
    protected async refundMedicalOrder()
    {
        try
        {
            
            
            if(this.loading)
            {
                return;
            }

            

            this.loading = true;
            let res: any = await MedicalOrderService.instance.refundMedicalOrder(this.id);

            if(res.code == 1000) {
                this.showRefundDialog = false;
                this.showOverlay = true;
                this.loading = false;
            }

            this.getMedicalOrderDetail();
            this.showRefundDialog = false;

        }
        catch(err)
        {
            this.showRefundDialog = false;
            this.showOverlay = true;
            this.loading = false;
            // Toast(JSON.stringify(err));
        }
    }

    /**
     * 预约服务订单
     * @protected
     * @returns void
     */
    protected onAppointment()
    {
        this.$router.push({name:"medical-order-appointment", query:{id: this.id, rightsName: this.dataModel.rightsName ? encodeURI(this.dataModel.rightsName) : ''}})
    }

    /**
     * 去使用
     * @protected
     * @returns void
     */
    //  productsLink
    protected onApply(dataModel)
    {
        if(this.dataModel.productsLinkType == 2) {
            // 链接保存
            this.$router.push({name: "medical-buy"});
            localStorage.setItem("productsLink", this.dataModel.productsLink);
        }
    }


    /**
     * 修改日期
     * @protected
     * @returns void
     */
    protected onDateConfirm(date: any, period: any): void
    {
        if(date)
        {
            this.changeData.changeTime = dayjs(date).format("YYYY/MM/DD");
            this.changeData.timePeriod = period;
        }

        this.showDate = false;
        this.showChangeDateDialog = true;

    }

    /**
     * 取消预约
     * @protected
     * @returns void
     */
    protected async onCancelAppointment()
    {
        try
        {
            let {content: result} = await MedicalOrderService.instance.cancelAppointMedicalOrder(this.id);
            Toast("操作成功!");
            this.showCancelDialog = false;
            this.getMedicalOrderDetail();
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 修改时间
     * @protected
     * @returns void
     */
    protected async onChangeDate()
    {
        try
        {
            let {content: result} = await MedicalOrderService.instance.changeMedicalOrderSchedule(this.changeData);
            console.log(result);
            Toast(result?.msg == "请求成功" ? '改约成功' : result?.msg);
            this.showChangeDateDialog = false;
            this.getMedicalOrderDetail();
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 复制
     * @param str 
     */
     private async copyOrderNo(str): Promise<void>
    {
        let save = function (e) {
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        };
        let input = document.createElement("input");
        input.value = str;
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, input.value.length), document.execCommand("Copy");
        document.body.removeChild(input);
        Toast({
            message: "复制成功",
            duration: 1000
        });
    }

    /**
     * 跳转地图
     * @private
     * @returns void
     */
    private async openMap(hospitalOut: any): Promise<any>
    {
        if(hospitalOut)
        {
            let lat = hospitalOut.appointOrgLatitude;
            let lng =hospitalOut.appointOrgLongitude;
            let address = hospitalOut.appointOrgAddress;
            let orgName = hospitalOut.appointOrgName;

            if(await tools.isWechat())
            {
                let currentUrl = location.href;
                let {content: result} = await WechatService.instance.getWechatJSSDK(currentUrl);
                if(result.data)
                {
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: result.data.appId, // 必填，公众号的唯一标识
                        timestamp: result.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
                        signature: result.data.signature, // 必填，签名
                        jsApiList: ["openLocation"] // 必填，需要使用的JS接口列表
                    });

                    wx.ready(function()
                    {
                        wx.openLocation({
                            latitude: parseFloat(lat), // 纬度，范围为-90~90，负数表示南纬
                            longitude: parseFloat(lng), // 经度，范围为-180~180，负数表示西经
                            scale: 14, // 缩放比例
                            name: orgName,
                            address: address, // 地图缩放级别,整形值,范围从1~28。默认为最大
                            infoUrl: ""
                        });
                    });
                }
            }
            else
            {
                // 腾讯地图
                // let href = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${lat},${lng};addr:${this.orgDetailInfo.org_name}`;
                // 高德
                let href = `https://uri.amap.com/marker?position=${lng},${lat}&name=${orgName}&coordinate=gaode&callnative=1`;
                window.open(href);
            }

        }

    }

}
