
  import { Component, Vue } from "vue-property-decorator";
  import { HealthProductService, UserService, WechatService } from "src/services";
  import { Toast, Dialog } from "vant";
  import { Pay, tools } from "src/utils";
  
  Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate", // for vue-router 2.2+
  ]);
  
  @Component
  export default class healthInsuranceDetail extends Vue {
    // private productsLink: string = "";
  
    /**
     * 商品详情id
     * @private
     * @returns string
     */
    private get productsLink(): string {
      console.log(localStorage.getItem("productsLink"),'productsLink');
      return localStorage.getItem("productsLink") || "";
    }
  }
  