
import { Component, Vue } from "vue-property-decorator";
import { MedicalOrderService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import globalConfig from "src/config/index";

@Component
export default class GoodsDetail extends Vue
{
    /**
     * 日期处理函数
     * @private
     * @returns any
     */
    private dayjs: any = dayjs;

    /**
     * 数据模型
     * @private
     * @returns any
     */
    private dataModel: any =
    {
        actualAmount: null,
        appointEmployerMemberId: null,
        appointMemberId: null,
        appointMemberName: null,
        appointOrgId:null,
        appointOrgName: null,
        appointTime: null,
        appointTimePeriod: null,
        cancelTime: null,
        completedTime: null,
        createdTime: null,
        id: null,
        isNeedAppointment:true,
        isOvertimeUnPay: null, 
        marketPrice:null,
        orderNo:"",
        payDeadlineTime: null,
        payedTime: null,
        price: null,
        productId:null,
        productName:"",
        productPict:"",
        productType: 2,
        productTypeStr: "医疗服务",
        refundedTime: null, 
        refundingTime: null,
        sourceKind: 2,
        sourceKindStr: "零售购买",
        status: 2,
        statusStr:"",
        supplierId: null,
        supplierLogo: "",
        supplierName:""
    };

    /**
     * 预约信息
     * @private
     * @returns 
     */
    private appointmentInfo: any =
    {
        orderId: 0,
        appointEmployerMemberId: 0,
        orgName: "",
        appointOrgId: 0,
        appointTime: "",
        appointTimePeriod: "",
        appointMemberId: 0
    };

    /**
     * 权益名称
     * @private
     * @returns string
     */
    private get rightsName(): string
    {
        return this.$route.query && this.$route.query.rightsName ? decodeURI((this.$route.query.rightsName as string)) : "";
    }

    /**
     * 是否显示确认退款弹框
     * @private
     * @returns 
     */
    private showDialog: boolean = false;

    /**
     * 选择人员
     * @protected
     * @returns boolean
     */
     protected showUserPopup: boolean = false;

    /**
     * 是否显示更多弹框
     * @private
     * @returns 
     */
    private showPopover: boolean = false;

    /**
     * 是否选择日期
     * @private
     * @returns boolean
     */
    private showDate: boolean = false;

    /**
     * 商品订单id
     * @private
     * @returns string
     */
    private get id(): string
    {
        return this.$route.query && (this.$route.query.id as string) || "";
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 机构名称
     * @private
     * @returns string
     */
    private get orgName(): string
    {
        return this.$route.query && (this.$route.query.orgName as string) || "";
    }

    /**
     * 机构id
     * @private
     * @returns string
     */
    private get orgId(): string
    {
        return this.$route.query && (this.$route.query.orgId as string) || "";
    }

    /**
     * 显示排期弹框
     * @private
     * @returns void
     */
    private created(): void
    {
        this.appointmentInfo.orgName = this.orgName;
        this.appointmentInfo.appointOrgId = this.orgId;
        this.getMedicalOrderDetail();
    }
    
    /**
     * 格式化日期
     * @private
     * @returns void
     */
    private formatDay(day, period): string
    {
        if(day)
        {
            let week = ["星期天","星期一","星期二","星期三","星期四","星期五","星期六"];
            let str = period ? `${day} ${period}` : `${day}(${week[dayjs(day).day()]}) ${period}`
            return str;
        }

        return null;
    }

    /**
     * 获取订单详情
     * @protected
     * @returns void
     */
    protected async getMedicalOrderDetail()
    {
        try
        {
            let {content: result} = await MedicalOrderService.instance.getMedicalOrderDetail(this.id);

            if(result.data)
            {
                this.dataModel = result.data;
                this.appointmentInfo.orderId = result.data.id;
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 显示排期弹框
     * @private
     * @returns void
     */
    private showDateModal(): void
    {
        if(!this.appointmentInfo.orgName)
        {
            Toast("请先选择预约机构!");
            return;
        }
        this.showDate = true;
    }

    /**
     * 选择机构
     * @private
     * @returns string
     */
    private onSelectOrg(): void
    {
        this.$router.push({name:"medical-org-list", query:{id: this.id, productId: this.dataModel.productId, rightsName: this.rightsName ? encodeURI(this.rightsName) : ''}});
    }

    /**
     * 日期确认选择
     * @private
     * @returns string
     */
    private onDateConfirm(date: any, period: any): void
    {
        if(date)
        {
            this.appointmentInfo.appointTime = dayjs(date).format("YYYY/MM/DD");
            this.appointmentInfo.appointTimePeriod = period;
        }

        this.showDate = false;
    }

    /**
     * 确认切换用户
     * @private
     * @returns void
     */
    protected onConfirmUser(data: any): void
    {
        this.appointmentInfo.appointMemberId = data.memberId;
        this.appointmentInfo.appointEmployerMemberId = data.employerMemberId;
        this.appointmentInfo.userName = data.memberName;
        this.showUserPopup = false;
    }

    /**
     * 预约信息
     * @private
     * @returns void
     */
    protected async onSubmit(data: any): Promise<void>
    {
        try
        {
            if(!this.appointmentInfo.orgName)
            {
                Toast("请先选择预约机构!");
                return;
            }
            if(!this.appointmentInfo.appointTime)
            {
                Toast("请先选择日期!");
                return;
            }
            if(!this.appointmentInfo.userName)
            {
                Toast("请先选择预约人!");
                return;
            }
           
            let {content: result} =  await MedicalOrderService.instance.appointMedicalOrder(this.appointmentInfo);

            if(result)
            {
                Toast("操作成功!");
                this.$router.push({name:"medical-order-appointment-sucess", query: {id: this.id}});
            }
        }
        catch(err)
        {
            Toast(err);
        }
       
    }
    /**
     * 复制
     * @param str 
     */
     private async copyOrderNo(str): Promise<void>
    {
        let save = function (e) {
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        };
        let input = document.createElement("input");
        input.value = str;
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, input.value.length), document.execCommand("Copy");
        document.body.removeChild(input);
        Toast({
            message: "复制成功",
            duration: 1000
        });
    }

}
